import React from 'react';

import Layout from '../components/Layout';
import ForgotPassword from '../components/ForgotPassword';

const ForgotPass = () => (
  <Layout>
    <ForgotPassword />
  </Layout>
);

export default ForgotPass;
