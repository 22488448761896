import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'gatsby';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Requests from '../actions';
import IconEmail from '../assets/images/ic-email.inline.svg';
import iconSuccess from '../assets/images/success.png';
import iconBack from '../assets/images/back.png';
import Button from './Button';

const ForgotPassword = () => {
  const { register, handleSubmit, watch, errors } = useForm();
  const [isSubmitted, setSubmit] = useState(false);
  const [loading, setLoading] = useState(false);

  const onSubmit = async ({ forgotPassword }) => {
    try {
      setLoading(true);
      const response = await Requests.forgotPass(forgotPassword);
      setLoading(false);
      if (response && response.statusCode === 200) {
        setSubmit(true);
      } else {
        const errorMessage =
          response.errorMessage || 'Something went wrong. Please try again later';
        toast.error(errorMessage, {
          hideProgressBar: true,
          className: 'toast',
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  const backBtn = (
    <Link to="/">
      <img src={iconBack} alt="back" />
      Back
    </Link>
  );

  return (
    <>
      <section className="forgotPassword">
        <div className="forgotPassword_wrapper-outer">
          {!isSubmitted ? (
            <div className="forgotPassword_wrapper-inner">
              {backBtn}
              <h1>Have you lost your username or password?</h1>
              <p>
                If you lost your password, insert your e-mail address in the field below. You will
                receive a message with instructions.
              </p>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="forgotPassword_container__email">
                  <div
                    className={`forgotPassword_container_input ${
                      errors.forgotPassword ? 'forgotPassword_container_input--error' : ''
                    }`}
                  >
                    <IconEmail className={watch('forgotPassword') ? 'active-svg' : ''} />
                    <input
                      type="email"
                      className="forgotPassword-input__email"
                      placeholder="Enter your email please"
                      name="forgotPassword"
                      ref={register({ required: true })}
                    />
                  </div>
                </div>
                <Button title="Send" loading={loading} />
              </form>
            </div>
          ) : (
            <div className="forgotPassword_wrapper-inner forgotPassword_wrapper-inner--success">
              <img src={iconSuccess} alt="Thank You." />
              <h1>Thank You.</h1>
              <p>
                We sent you an email with the reset link. Simply click on the link to reset your
                password
              </p>
              {backBtn}
            </div>
          )}
        </div>
        <ToastContainer />
      </section>
    </>
  );
};

export default ForgotPassword;
